*, *:before, *:after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

html, body, ul, ol, li, figure, blockquote, dl, dd {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Averta";
  src: url("https://d2kzpkef14nfxb.cloudfront.net/fonts/avertastdpe-regular.eot?#iefix") format("embedded-opentype"), url("https://d2kzpkef14nfxb.cloudfront.net/fonts/avertastdpe-regular.woff2") format("woff2"), url("https://d2kzpkef14nfxb.cloudfront.net/fonts/avertastdpe-regular.woff") format("woff"), url("https://d2kzpkef14nfxb.cloudfront.net/fonts/avertastdpe-regular.ttf") format("truetype");
}
@font-face {
  font-family: "Averta";
  src: url("https://d2kzpkef14nfxb.cloudfront.net/fonts/avertastdpe-semibold.eot");
  src: url("https://d2kzpkef14nfxb.cloudfront.net/fonts/avertastdpe-semibold.eot?#iefix") format("embedded-opentype"), url("https://d2kzpkef14nfxb.cloudfront.net/fonts/avertastdpe-semibold.woff2") format("woff2"), url("https://d2kzpkef14nfxb.cloudfront.net/fonts/avertastdpe-semibold.woff") format("woff"), url("https://d2kzpkef14nfxb.cloudfront.net/fonts/avertastdpe-semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
body {
  font-family: "Averta", sans-serif;
}

.mainContainer {

}

.content {
  margin-top: 40px;
}

@media screen and (max-width: 800px) {
  .mainContainer {
    width: 100%;
  }

  .content {
    padding: 0 20px;
  }
}

@media screen and (min-width: 801px) {
  .mainContainer {
    max-width: 800px;
    margin: 0 auto;
  }
}

body h1 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 16px;
}

body p {
  margin-bottom: 16px;
}

.illustration {
  /* Styles for mobile devices (default) */
  width: auto;
  height: 300px;
  background-repeat: no-repeat;
}

@media screen and (max-width: 640px) {
  .illustration {
    background-size: cover;
  }
}

@media screen and (min-width: 641px) {
  .illustration {
    background-size: contain;
  }
}

input[type="checkbox"] {
    margin: 10px;
}

input[type="button"], button {
    width: 400px !important;
}

.success {
  margin: 30px 0;
}